import { useMutation } from "@apollo/client";
import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import WORKBOOK_MUTATION from "./Mutation.workbook.graphql";
import VARIANT_MUTATION from "./Mutation.variant.graphql";
import WORKBOOK_DETAIL_QUERY from "../ExplainWorkbook/Query.graphql";
import WORKBOOK_LIST_QUERY from "../Query.graphql";
import {
  ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType,
  ExplainWorkbookDetails_getExplainWorkbookDetails_explainQueries as ExplainQueryType,
} from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import {
  DeleteExplainWorkbook,
  DeleteExplainWorkbookVariables,
} from "./types/DeleteExplainWorkbook";
import {
  DeleteExplainQuery,
  DeleteExplainQueryVariables,
} from "./types/DeleteExplainQuery";
import { useRoutes } from "utils/routes";
import Popover from "components/Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFingerprint, faUser } from "@fortawesome/pro-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import QueryTags, { QueryTagType } from "components/QueryTags";
import Button from "components/Button";

const ExplainVariantSidebar = ({
  workbook,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  explainQuery?: ExplainQueryType;
}) => {
  const { databaseId } = useParams();
  const { databaseQuery, databaseWorkbooks, databaseWorkbook } = useRoutes();
  const navigate = useNavigate();
  const focusedQuery = explainQuery || workbook.baselineQuery;
  const workbookSideBar = !explainQuery;
  const [deleteExplainWorkbook] = useMutation<
    DeleteExplainWorkbook,
    DeleteExplainWorkbookVariables
  >(WORKBOOK_MUTATION);
  const [deleteExplainQuery] = useMutation<
    DeleteExplainQuery,
    DeleteExplainQueryVariables
  >(VARIANT_MUTATION);
  const handleDeleteWorkbook = () => {
    if (window.confirm(`Delete workbook "${workbook.name}"?`)) {
      deleteExplainWorkbook({
        variables: { workbookId: workbook.id },
        refetchQueries: [
          {
            query: WORKBOOK_LIST_QUERY,
            variables: {
              databaseId: workbook.databaseId,
            },
          },
        ],
        awaitRefetchQueries: true,
        onCompleted: () => {
          navigate(databaseWorkbooks(databaseId));
        },
      });
    }
  };
  const handleDeleteVariant = () => {
    if (window.confirm(`Delete variant "${explainQuery.name}"?`)) {
      deleteExplainQuery({
        variables: { explainQueryId: explainQuery.id },
        refetchQueries: [
          {
            query: WORKBOOK_DETAIL_QUERY,
            variables: {
              workbookId: workbook.id,
              databaseId: workbook.databaseId,
            },
          },
        ],
        awaitRefetchQueries: true,
        onCompleted: () => {
          navigate(databaseWorkbook(databaseId, workbook.id));
        },
      });
    }
  };

  const queryTags: QueryTagType[] = [];
  if (focusedQuery.query) {
    queryTags.push(
      ...[
        {
          id: "fingerprint",
          key: (
            <Popover
              content="A query fingerprint represents the abstracted form of a query and enables the grouping of similar queries together. This value is calculated using the pg_query library."
              popupClassName="!text-[12px]"
            >
              <FontAwesomeIcon
                icon={faFingerprint}
                className="text-[#666] text-[10px]"
              />{" "}
              fingerprint
            </Popover>
          ),
          value: focusedQuery.queryFingerprint,
        },
        {
          id: "role",
          key: (
            <>
              <FontAwesomeIcon
                icon={faUser}
                className="text-[#666] text-[10px]"
              />{" "}
              role
            </>
          ),
          value: focusedQuery.query.postgresRole.name,
        },
      ],
    );
  }

  return (
    <div className="w-[320px]">
      <div className="leading-7 mt-0 font-medium">Description</div>
      <div className="mb-3">
        {workbook.description ?? (
          <span className="italic text-[#606060]">No description</span>
        )}
      </div>
      <hr />
      <div className="leading-7 mt-0 font-medium">
        {workbookSideBar && "Baseline "}Query
      </div>
      <div className="mb-3">
        {focusedQuery.query ? (
          <Link to={databaseQuery(databaseId, focusedQuery.query.id)}>
            #{focusedQuery.query.id}
          </Link>
        ) : (
          <span className="italic text-[#606060]">
            No match with existing queries
          </span>
        )}
      </div>
      <div className="leading-7 mt-0 font-medium">Query tags</div>
      <div className="mb-3">
        {queryTags.length > 0 ? (
          <QueryTags tags={queryTags} />
        ) : (
          <span className="italic text-[#606060]">No query tags</span>
        )}
      </div>
      <hr />
      {workbookSideBar ? (
        <Button bare onClick={handleDeleteWorkbook} className="!text-[#d43f3a]">
          <FontAwesomeIcon icon={faTrashAlt} /> Delete workbook
        </Button>
      ) : (
        focusedQuery.id !== workbook.baselineQuery.id && (
          <Button
            bare
            onClick={handleDeleteVariant}
            className="!text-[#d43f3a]"
          >
            <FontAwesomeIcon icon={faTrashAlt} /> Remove variant
          </Button>
        )
      )}
    </div>
  );
};

export default ExplainVariantSidebar;
