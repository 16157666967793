import Panel from "components/Panel";
import React from "react";

import QUERY from "../ExplainWorkbook/Query.graphql";
import {
  ExplainWorkbookDetails,
  ExplainWorkbookDetailsVariables,
  ExplainWorkbookDetails_getExplainWorkbookDetails_explainQueries as ExplainQueryType,
} from "../ExplainWorkbook/types/ExplainWorkbookDetails";
import { formatMs } from "utils/format";
import Grid, { MsCell, NumberCell } from "components/Grid";
import { faPlay } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExpandableSQL from "components/ExpandableSQL";
import Identicon from "components/Identicon";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useRoutes } from "utils/routes";
import { faMagnifyingGlassArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { useQuery } from "@apollo/client";
import Loading from "components/Loading";
import PageContent from "components/PageContent";
import {
  ExplainWorkbookFeatureNav,
  ExplainWorkbookHeader,
  ParameterSetsPanel,
} from "../ExplainWorkbook";
import ExplainResultPanel from "../ExplainResultPanel";
import EditParameterSets, { missingValueAliases } from "../EditParameterSets";
import RunExplain from "../RunExplain";
import ExplainVariantSidebar from "../ExplainVariantSidebar";

// This is not really tab, but for which page to show
type ExplainVariantTabType = "run";

const ExplainVariant = ({ tab }: { tab?: ExplainVariantTabType }) => {
  const { databaseId, workbookId, variantId, resultId } = useParams();
  const { databaseWorkbookVariantResult, databaseWorkbookVariantRun } =
    useRoutes();
  const navigate = useNavigate();

  const { loading, error, data } = useQuery<
    ExplainWorkbookDetails,
    ExplainWorkbookDetailsVariables
  >(QUERY, {
    variables: { workbookId, databaseId },
  });
  if (loading || error) {
    return <Loading error={!!error} />;
  }
  const workbook = data.getExplainWorkbookDetails;
  const explainQuery =
    workbook.baselineQuery.id === variantId
      ? workbook.baselineQuery
      : workbook.explainQueries.find((val) => val.id === variantId);

  const featureNav = (
    <ExplainWorkbookFeatureNav workbook={workbook} databaseId={databaseId} />
  );

  // When there is zero result uploaded, or with "run" path, show Run EXPLAIN
  if (explainQuery.explainResults.length === 0 || tab === "run") {
    // Check if this variant's aliases contain missing value aliases
    // (can't run EXPLAIN until missing value aliases are all set)
    const missingAliases = Array.from(
      new Set(
        workbook.parameterSets.flatMap((ps) =>
          missingValueAliases(workbook, ps.id).map((v) => `$${v}`),
        ),
      ),
    );
    if (
      missingAliases.some((alias) =>
        Object.values(explainQuery.paramRefAliasMap).includes(alias),
      )
    ) {
      return (
        <EditParameterSets
          workbook={workbook}
          explainQuery={explainQuery}
          featureNav={featureNav}
        />
      );
    }
    if (tab !== "run") {
      // When showing RunExplain component, make sure to show via /run path
      navigate(databaseWorkbookVariantRun(databaseId, workbookId, variantId));
      return null;
    }

    return (
      <RunExplain
        workbook={workbook}
        explainQuery={explainQuery}
        featureNav={featureNav}
      />
    );
  }

  const variantData = workbook.parameterSets.map((ps) => {
    const result = explainQuery.explainResults.find(
      (er) => er.parameterSetId === ps.id,
    );
    return {
      resultId: result?.id,
      setId: ps.id,
      paramSetName: ps.name,
      planFingerprint: result?.planFingerprint,
      totalCost: result?.totalCost,
      runtimeMs: result?.runtimeMs,
      totalBlkReadTime: result?.totalBlkReadTime,
    };
  });

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
      featureInfo={
        <ExplainWorkbookHeader workbook={workbook} showCreateButton />
      }
      pageCategory="explains"
      pageName="workbooks"
      layout={resultId ? "bare" : "sidebar"}
      featureNav={featureNav}
    >
      {resultId ? (
        <ExplainResultPanel
          workbook={workbook}
          explainQuery={explainQuery}
          blockSize={data.getServerDetails.blockSize}
        />
      ) : (
        <>
          <ExplainQueryPanel explainQuery={explainQuery} />
          <Panel title="Query Plans">
            <Grid
              className="grid-cols-[40px_1fr_140px_repeat(3,minmax(5%,110px))]"
              data={variantData}
              pageSize={5}
              defaultSortBy="setId"
              columns={[
                {
                  field: "setId",
                  header: "",
                  disableSort: true,
                  renderer: function IconCell({ rowData }) {
                    if (rowData.resultId == null) {
                      return (
                        <Link
                          to={databaseWorkbookVariantRun(
                            databaseId,
                            workbook.id,
                            explainQuery.id,
                          )}
                        >
                          <FontAwesomeIcon icon={faPlay} fixedWidth />
                        </Link>
                      );
                    }
                    return (
                      <Link
                        to={databaseWorkbookVariantResult(
                          databaseId,
                          workbook.id,
                          explainQuery.id,
                          rowData.resultId,
                        )}
                      >
                        <FontAwesomeIcon
                          icon={faMagnifyingGlassArrowRight}
                          fixedWidth
                        />
                      </Link>
                    );
                  },
                },
                {
                  field: "paramSetName",
                  header: "Parameter Set",
                },
                {
                  field: "planFingerprint",
                  header: "Plan",
                  renderer: function PlanFingerprintCell({ fieldData }) {
                    return (
                      <>
                        <Identicon identity={fieldData} />
                        <span title={fieldData}>
                          {fieldData.substring(0, 7)}
                        </span>
                      </>
                    );
                  },
                  nullValue: "-",
                },
                {
                  field: "totalCost",
                  header: "Est. Cost",
                  renderer: NumberCell,
                  style: "number",
                  nullValue: "-",
                },
                {
                  field: "runtimeMs",
                  header: "Runtime",
                  renderer: MsCell,
                  style: "number",
                  nullValue: "-",
                },
                {
                  field: "totalBlkReadTime",
                  header: "I/O Read Time",
                  renderer: MsCell,
                  style: "number",
                  nullValue: "-",
                },
              ]}
            />
          </Panel>
          <ParameterSetsPanel workbook={workbook} explainQuery={explainQuery} />
        </>
      )}
      {/* sidebar */}
      {!resultId && (
        <ExplainVariantSidebar
          workbook={workbook}
          explainQuery={explainQuery}
        />
      )}
    </PageContent>
  );
};

const ExplainQueryPanel = ({
  explainQuery,
}: {
  explainQuery: ExplainQueryType;
}) => {
  const runtimeValues = explainQuery.explainResults.map((val) => val.runtimeMs);
  return (
    <div className="rounded-md bg-[#f7fafc] grid grid-cols-2 p-4 mb-4 gap-2">
      <div className="col-span-2 text-[18px] leading-6">
        {explainQuery.name}
      </div>
      <div className="col-span-2">
        <ExpandableSQL sql={explainQuery.queryTextWithAlias} />
      </div>
      <div>
        <div className="text-[11px]">MIN RUNTIME</div>
        <div className="text-[16px]">
          {runtimeValues.length > 0
            ? formatMs(Math.min(...runtimeValues))
            : "-"}
        </div>
      </div>
      <div>
        <div className="text-[11px]">MAX RUNTIME</div>
        <div className="text-[16px]">
          {runtimeValues.length > 0
            ? formatMs(Math.max(...runtimeValues))
            : "-"}
        </div>
      </div>
    </div>
  );
};

export default ExplainVariant;
